import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./LocationModel.css";
import Map from "./Map";
import { Formik, Form } from "formik";
import FormikControl from "../../../Formik/FormikControl";
import * as Yup from 'yup';
import { MIN_CART_VALUE, EXPRESS_DELIVERY_CHARGE } from '../../../utils/constants';
import { withRouter } from "react-router";

const FinetuneLocation = (props) => {

    const toggle = () => {
        props.closeFinetuneLocation();
        !props.deliverStatus && props.history.push("/");
    };

    const [latitude, setLattitude] = useState();
    const [longitude, setLongitude] = useState();
    const [geoLocation, setGeoLocation] = useState();
    const [emailField, setEmailField] = useState(false);

    useEffect(() => {
        !props.deliverStatus && getLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.deliverStatus])
    // const [googleMap, setGoogleMap] = useState(false);

    const renderMap = () => {
        return <Map

            google={props.google}
            center={{ lat: latitude, lng: longitude }}
            height='300px'
            zoom={15}
            updateGeoLocation={updateGeoLocation}

        >

        </Map>
    }

    useEffect(() => {
        renderMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latitude])

    function getLocation() {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getCoordinates, handleLocationError);
        } else {
            props.showToastMessage({ type: "error", message: "Geolocation is not supported by this browser." });
            props.resetToastMessage();
        }
    }
    function getCoordinates(position) {
        console.log(position, "position")
        setLattitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
    }
    function handleLocationError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                // props.showToastMessage({ type: "error", message: "User denied the request for Geolocation." });
                // props.resetToastMessage();
                break;
            case error.POSITION_UNAVAILABLE:
                props.showToastMessage({ type: "error", message: "Location information is unavailable." });
                props.resetToastMessage();
                break;
            case error.TIMEOUT:
                props.showToastMessage({ type: "error", message: "The request to get user location timed out." });
                props.resetToastMessage();
                break;
            case error.UNKNOWN_ERROR:
                props.showToastMessage({ type: "error", message: "An unknown error occurred." });
                props.resetToastMessage();
                break;
            default:
                return null;
        }
    }
    function setLocalStore(response) {
        props.setUserLocation({
            lat: latitude,
            lng: longitude,
        })
        setEmailField(false)
        console.log(response, "responseAddress")
        localStorage.setItem(MIN_CART_VALUE, response.data.data.minimum_cart_value ? response.data.data.minimum_cart_value : 0);
        localStorage.setItem(EXPRESS_DELIVERY_CHARGE, response.data.data.express_delivery_charge ? response.data.data.express_delivery_charge : 0);
        localStorage.setItem("locationName", localStorage.getItem('locationNameOnChangeRequest'));


        localStorage.setItem("geo_latitude", latitude);
        localStorage.setItem("geo_longitude", longitude);
       
        localStorage.setItem("store_id", response.data.data.id);
    }
    function updateGeoLocation(unit, address) {

        console.log(address, "address")
        setLattitude(unit.lat);
        setLongitude(unit.lng);
        setGeoLocation(address)
      

    }
    const updateDeliveryLocation = () => {
        props.getDeliveryRange({
            geo_latitude: latitude,
            geo_longitude: longitude
        }, (response) => {

            console.log(response, "localResponse")
            response.data.delivery_status ? setLocalStore(response)

                :
                console.log(response, "localResponse")

            response.data.delivery_status ? props.showToastMessage({ type: "success", message: response.message }, setEmailField(false)) : props.showToastMessage({ type: "error", message: response.message }, setEmailField(true));
            props.resetToastMessage();
            console.log(response.message);
            response.data.delivery_status && props.closeFinetuneLocation();
        }, (error) => {
            props.showToastMessage(error);
            console.log(error);
        });

        // toggle();
    }

    const initialValues = {

        email: '',
        mobile: '',
        geo_address: geoLocation,
        geo_latitude: latitude,
        geo_longitude: longitude,

    }
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
    })
    const onSubmit = (values) => {
        props.checkDeliveryRequest(values)

    }
    console.log(props, "checkDeliveryRequest")
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal isOpen={props.show} toggle={toggle} className="locationPopup" size="lg">
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div className="modal-body">
                        <h2 className="mb-0">Fine Tune Your Location</h2>
                        <p className="mb-2">Please move the pin to the exact location of delivery</p>
                        {/* <p>
                            <div class="form-group">
                            
                                <input class="form-control" name="map" id="map" type="text" placeholder="Search or move the map" />
                            </div>
                        </p> */}
                        {renderMap() ? renderMap() :
                            <Map

                                google={props.google}
                                //   center={{lat: latitude, lng: longitude}}
                                height='300px'
                                zoom={15}
                                updateGeoLocation={updateGeoLocation}

                            >

                            </Map>
                        }


                        <Button className="btn btn-dark mr-2 mt-4" color="primary" disabled={!(latitude && longitude)} onClick={() => {
                            latitude && longitude && updateDeliveryLocation();
                        }}>Confirm My Location</Button>


                    </div>
                </ModalBody>
                {console.log(emailField, "emailField")}
                {emailField &&
                    <ModalFooter style={{ zIndex: '999' }} className="justify-content-center">

                        <div className="px-3 text-center">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {() => {

                                    return (
                                        <Form>
                                            <h5 > Location Request</h5>
                                            <div class="mb-3">{`Enter your email and we will contact you when delivery to your location is available.`}</div>

                                            <div style={{ zIndex: '1000' }} className="form-group">
                                                <FormikControl control="input" className="form-control" name="email" id="email" type="text" placeholder="Enter Email Address" />
                                            </div>
                                            <button type="submit" className="btn btn-dark mr-2" color="primary"

                                            >Inform Me
                    </button>
                                        </Form>
                                    )
                                }}
                            </Formik>

                        </div>


                    </ModalFooter>
                }
            </Modal>
        </div >
    );
}
export default withRouter(FinetuneLocation);

