import {MIN_CART_VALUE, EXPRESS_DELIVERY_CHARGE} from './constants';

export function getFormData(object) {
    const formData = new FormData();
    for (var key in object) {
        formData.append(key, object[key]);
    }
  
    console.log(formData);
    return formData;
}

  export function delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

export function getDeliveryRangeData(getDeliveryRange) {
    getDeliveryRange({
        geo_latitude: localStorage.getItem("geo_latitude"),
        geo_longitude: localStorage.getItem("geo_longitude")
    }, (response) => {
        localStorage.setItem(MIN_CART_VALUE, response.data.data.minimum_cart_value);
        localStorage.setItem(EXPRESS_DELIVERY_CHARGE, response.data.data.express_delivery_charge ? response.data.data.express_delivery_charge : 0);
        // localStorage.setItem("locationName", response.data.data.geo_address);
    }, (error) => {
    });
}