import {
  API_INVOCATION,
  SET_NEW_ARRIVAL_PRODUCT,
  SET_CATEGORY_BASED_PRODUCT,
  SET_CURRENT_PRODUCT,
  ADD_TO_CART,
  ADD_TO_CART_ITEM_INC,
  ADD_TO_CART_ITEM_DEC,
  ADD_TO_CART_SAVE_ON_CHECKOUT,
  GET_USER_CART_DETAILS,
  ADD_FAVORITE_PRODUCT,
  GET_FAVORITE_PRODUCT,
  ADD_PRODUCT_REVIEW,
  GET_PRODUCT_REVIEW
} from "../../actionType";
import * as constdata from "../../../utils/constants";
import * as consturl from "../../../utils/url";
import { getToken } from "../../../utils/token";

/**
 *  get product list
 */
export const getProductList = (_payload) => {
  const url = `${consturl.BASE_URL}${consturl.PRODUCT_LIST}?search=${
    _payload && _payload.search ? _payload.search : ""
  }&category_id=${
    _payload && _payload.categoryId ? _payload.categoryId : ""
  }&sub_category_id=${
    _payload && _payload.subcategoryId ? _payload.subcategoryId : ""
  }&store_id=${_payload && _payload.storeId ? _payload.storeId : ""}&order=${
    _payload && _payload.order ? _payload.order : ""
  }&page=${_payload && _payload.page ? _payload.page : ""}`;
  const payload = {
    action: _payload.forCategory
      ? SET_CATEGORY_BASED_PRODUCT
      : SET_NEW_ARRIVAL_PRODUCT,
    method: constdata.GET,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url: url,
  };
  return { type: API_INVOCATION, payload };
};

/**
 *  get product details
 */
export const getProduct = (_payload) => {
  const url = `${consturl.BASE_URL}${consturl.PRODUCT}/${
    _payload && _payload.id ? _payload.id : ""
  }/view`;
  const payload = {
    action: SET_CURRENT_PRODUCT,
    method: constdata.GET,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url: url,
  };
  return { type: API_INVOCATION, payload };
};

export const addToCartProduct = (_payload) => {
  if (getToken()) {
    const url = `${consturl.BASE_URL}carts/store`;
    const data = {
      cart: [
        {
          product_id: _payload.id,
          quantity: 1,
        },
      ],
    };
    const payload = {
      action: ADD_TO_CART,
      method: constdata.POST,
      apiConfig: {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          client_id: constdata.CLIENT_ID,
          client_secret: constdata.CLIENT_SECRET,
        },
      },
      url,
      data,
    };
    return { type: API_INVOCATION, payload };
  } else {
    return { type: ADD_TO_CART, payload: _payload };
  }
};

export const addToCartProductQtyInc = (_payload) => {
  if (getToken()) {
    const url = `${consturl.BASE_URL}carts/store`;
    const data = {
      cart: [
        {
          product_id: _payload,
          quantity: 1,
        },
      ],
    };
    const payload = {
      action: ADD_TO_CART_ITEM_INC,
      method: constdata.POST,
      apiConfig: {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          client_id: constdata.CLIENT_ID,
          client_secret: constdata.CLIENT_SECRET,
        },
      },
      url,
      data,
    };
    return { type: API_INVOCATION, payload };
  } else {
    return { type: ADD_TO_CART_ITEM_INC, payload: _payload };
  }
};

export const addToCartProductQtyDec = (_payload) => {
  if (getToken()) {
    const url = `${consturl.BASE_URL}carts/remove`;
    const data = {
      product_id: _payload,
      quantity: 1,
    };
    const payload = {
      action: ADD_TO_CART_ITEM_DEC,
      method: constdata.POST,
      apiConfig: {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          client_id: constdata.CLIENT_ID,
          client_secret: constdata.CLIENT_SECRET,
        },
      },
      url,
      data,
    };
    return { type: API_INVOCATION, payload };
  } else {
    return { type: ADD_TO_CART_ITEM_DEC, payload: _payload };
  }
};

export const addToCartSaveOnCheckout = (_payload, resolve, reject) => {
  let cartDetails = [];

  cartDetails = _payload.products.map((product) => ({
    product_id: product.product_id,
    quantity: product.quantity,
  }));

  const url = `${consturl.BASE_URL}carts/store`;
  const data = {
    cart: cartDetails,
  };
  const payload = {
    action: ADD_TO_CART_SAVE_ON_CHECKOUT,
    method: constdata.POST,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
    data,
    resolve,
    reject,
  };
  return { type: API_INVOCATION, payload };
};

export const getUserCartDetails = (_payload) => {
  const url = `${consturl.BASE_URL}cart`;
  const payload = {
    action: GET_USER_CART_DETAILS,
    method: constdata.GET,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
  };
  return { type: API_INVOCATION, payload };
};
export const addFavoriteProduct = (_payload, resolve, reject) => {
  const data = _payload;
  const url = `${consturl.BASE_URL}${consturl.ADDFAVORITEPROFILE}`;
  const payload = {
    action: ADD_FAVORITE_PRODUCT,
    method: constdata.POST,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
    data,
    resolve,
    reject,
  };
  return { type: API_INVOCATION, payload };
};

export const removeFavoriteProduct = (_payload, resolve, reject) => {
  const data = _payload;
  const url = `${consturl.BASE_URL}${consturl.ADDFAVORITEPROFILE}/${data.product_id}`;
  const payload = {
    action: ADD_FAVORITE_PRODUCT,
    method: constdata.DELETE,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
    data,
    resolve,
    reject,
  };
  return { type: API_INVOCATION, payload };
};

export const getFavoriteProduct = (_payload, resolve, reject) => {
  const url = `${consturl.BASE_URL}${consturl.GETFAVPRODUCT}`;
  const payload = {
    action: GET_FAVORITE_PRODUCT,
    method: constdata.GET,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
    resolve,
    reject,
  };
  return { type: API_INVOCATION, payload };
};
/**
 * ADd Product Review
 *
 */

export const addProductReview = (_payload, resolve, reject) => {
  const url = `${consturl.BASE_URL}${consturl.ADD_PRODUCT_REVIEW}`;
  const payload = {
    action: ADD_PRODUCT_REVIEW,
    method: constdata.POST,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
    ..._payload,
    resolve,
    reject,
  };
  return { type: API_INVOCATION, payload };
};

export const getProductReview = (_payload, resolve, reject) => {
  const url = `${consturl.BASE_URL}${consturl.ADD_PRODUCT_REVIEW}/${_payload}`;
  const payload = {
    action: GET_PRODUCT_REVIEW,
    method: constdata.GET,
    apiConfig: {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        client_id: constdata.CLIENT_ID,
        client_secret: constdata.CLIENT_SECRET,
      },
    },
    url,
    ..._payload,
    resolve,
    reject,
  };
  return { type: API_INVOCATION, payload };
};
