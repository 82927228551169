/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// import AuthLayout from "../layouts/Auth";
// import ErrorLayout from './layouts/Error';
import GuestLayout from "../Layout/Guest";
import UserProfileLayout from "../Layout/UserProfile";

// const a=0

const routes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: () => <Redirect to="/" />,
  // },
  {
    path: "/auth",
    component: GuestLayout,
    // AuthLayout,
    routes: [
      {
        component: () => <Redirect to="/auth" />,
      },
    ],
  },
  {
    route: "*",
    component: GuestLayout,
    routes: [
      {
        path: "/",
        exact: true,
        component: lazy(() => import("../View/Home")),
        isPrivate: false,
      },
      {
        path: "/category",
        exact: true,
        component: lazy(() => import("../View/ShopCategory")),
        isPrivate: false,
      },
      {
        path: "/category/:id",
        exact: true,
        component: lazy(() => import("../View/ShopCategory")),
        isPrivate: false,
      },
      {
        path: "/product/:id",
        exact: true,
        component: lazy(() => import("../View/ProductDetail")),
        isPrivate: false,
      },
      {
        path: "/cart-order-list",
        exact: true,
        component: lazy(() => import("../View/ShoppingCart")),
        isPrivate: false,
      },
      {
        path: "/contact",
        exact: true,
        component: lazy(() => import("../View/ContactUs")),
        isPrivate: false,
      },
      {
        path: "/faq",
        exact: true,
        component: lazy(() => import("../View/FAQ")),
        isPrivate: false,
      },
      {
        path: "/sell-with-us",
        exact: true,
        component: lazy(() => import("../View/SellWithUs")),
        isPrivate: false,
      },
      {
        path: "/about-us",
        exact: true,
        component: lazy(() => import("../View/AboutUs")),
        isPrivate: false,
      },
      {
        path: "/checkout",
        exact: true,
        component: lazy(() => import("../View/Checkout")),
        isPrivate: true,
      },
      {
        path: "/checkout-response",
        exact: true,
        component: lazy(() => import("../View/CheckoutResponse")),
        isPrivate: true,
      },
      {
        route: "/user-profile",
        component: UserProfileLayout,
        routes: [
          {
            path: "/user-profile/my-profile",
            exact: true,
            component: lazy(() => import("../View/UserProfile/Myprofile/index.js")),
            isPrivate: true,
          },
          {
            path: "/user-profile/my-order",
            exact: true,
            component: lazy(() => import("../View/UserProfile/MyOrders/index")),
            isPrivate: true,
          },
          {
            path: "/user-profile/order-detail/:id",
            exact: true,
            component: lazy(() => import("../View/UserProfile/OrderDetails/index")),
            isPrivate: true,
          },
          {
            path: "/user-profile/track-order",
            exact: true,
            component: lazy(() => import("../View/UserProfile/TrackOrder")),
            isPrivate: true,
          },
          {
            path: "/user-profile/favorite-products",
            exact: true,
            component: lazy(() => import("../View/UserProfile/MyFavProducts")),
            isPrivate: true,
          },
          {
            path: "/user-profile/payment-detail",
            exact: true,
            component: lazy(() => import("../View/UserProfile/TrackOrder")),
            isPrivate: true,
          },
          {
            path: "/user-profile/our-addresses",
            exact: true,
            component: lazy(() => import("../View/UserProfile/MyAddress/index")),
            isPrivate: true,
          },
          {
            path: "/user-profile/add-address",
            exact: true,
            component: lazy(() => import("../View/UserProfile/AddNewAddress/index")),
            isPrivate: true,
          },
          
          {
            component: () => <Redirect to="/user-profile/my-profile" />,
          },
        ],
      },
      {
        component: () => <Redirect to="/" />,
      },
    ],
  },

];

export default routes;
