export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const PRODUCT = "product";
export const ADD_ADDRESS="user/address"
export const GET_ALL_ADDRESS="user/addresses"
export const PRODUCT_LIST = "product/list";
export const CATEGORY_LIST= "categories/list";
export const GET_CATEGORIES = "get-categories";
export const GET_CATEGORY_WITH_PAGINATION = "categories/list";
export const VERIFY_VOUCHER = "voucher/verify";
export const BANNER = "get-banners";
export const CONTACT = "enquiries";
export const GETRANGE = "delivery-range";
export const FAQ ="get-faq";
export const APP_LINK = "get-app-download-link";
export const PROFILE ="user";
export const ADDFAVORITEPROFILE ="favorite-product";
export const GETFAVPRODUCT="user-favorite-product";
export const PAYMENTSTATUS = "payment-status";
export const USERS ="users/";
export const CHECK_DELIVERY_REQUEST="check-delivery"
export const TAKE_ORDERS ="orders";

export const ADD_PRODUCT_REVIEW ="product/reviews"