import {
    CHECK_PAYMENT_STATUS_SUCCESS,
    CHECK_PAYMENT_STATUS_ERROR,
    CART_CHECKOUT_SAVE_SUCCESS,
    CART_CHECKOUT_SAVE_ERROR,
    DELIVERY_ADDRESS_SELECT,
    BILLING_ADDRESS_SELECT
} from "../actionType";

const INITIAL_STATE = {
    paymentStatus: null,
    paymentStatusError: null,
    cartCheckoutError: null,
    billAddress: null,
    delAddress: null
};
const checkout = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHECK_PAYMENT_STATUS_SUCCESS:
            return {
                ...state,
                paymentStatus: action.payload.data,
                paymentStatusError: null,
            };
        case CHECK_PAYMENT_STATUS_ERROR:
            
            return {
                ...state,
                paymentStatusError: action.payload,
                paymentStatus: null,
            };
        case CART_CHECKOUT_SAVE_SUCCESS:
            return {
                ...state,
                cartCheckoutError: null
            };
        case CART_CHECKOUT_SAVE_ERROR:
            return {
                ...state,
                cartCheckoutError: action.payload
            };
        case DELIVERY_ADDRESS_SELECT:
            return {
                ...state,
                delAddress : action.payload
            };
        case BILLING_ADDRESS_SELECT:
            return {
                ...state,
                billAddress : action.payload
            }
        default:
            return state
    }
};
export default checkout;