import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Internal imports
import history from "./Store/history/index";
import routes from "./Routes/Routes";
import PrivateRoute from "./Routes/PrivateRoutes";
import LoginRoute from "./Routes/LoginRoute";
import ToastMessage from './Components/ToastMessage';
import Loader from './Components/Loader';
import LoginModal from './Components/Modal/LoginModal/LoginModal';
import LocationModal from './Components/Modal/LocationModal/LocationModal';
import FinetuneLocation from './Components/Modal/FinetuneLocation';
import { closeLogin,closeSignup ,openLogin ,openSignup } from './Store/action/login';
import { getUserProfile } from './Store/action/user'
import { closeLocation, getDeliveryRange, openFinetuneLocation, closeFinetuneLocation, setUserLocation,checkDeliveryRequest } from './Store/action/location';
import { showToastMessage, resetToastMessage, setStartLoader, setStopLoader } from './Store/action/loader';


// Style imports
import "./App.css";
import "./index.css";
import SignupModal from "./Components/Modal/SignupModal/SignupModal";

const resolvePrivateRoutes = (routes) => {
  if (routes && Array.isArray(routes)) {
    return routes.map((route) => {
      if (route.isPrivate) {
        route.render = (props) => (
          <PrivateRoute component={route.component} {...props} />
        );
      }
      if (route.isAuth) {
        route.render = (props) => (
          <LoginRoute component={route.component} {...props} />
        );
      }
      if (route.routes) {
        resolvePrivateRoutes(route.routes);
      }
      return route;
    });
  }
};

function App(props) {
  const { 
    toast,
    openLogin, 
    loaderStatus, 
    openSignup,
    
    showLogin,
    showSignup,
    closeSignup, 
    showToastMessage, 
    resetToastMessage, 
    closeLogin, 
    location, 
    closeLocation, 
    getDeliveryRange, 
    openFinetuneLocation,
    closeFinetuneLocation,
    setStartLoader, 
    setStopLoader,
    setUserLocation,
    checkDeliveryRequest,
    deliveryData,
    deliveryDataError,
    getUserProfile,
    userProfile

  
  } = props;
  return (
    
    <Router history={history} basename={process.env.PUBLIC_URL}>
      
      <ToastMessage openLogin={openLogin}toastData={toast && toast} />
      <LoginModal show={showLogin} showToastMessage={showToastMessage} resetToastMessage={resetToastMessage} closeLogin={closeLogin} openSignup={openSignup} openLogin={openLogin} />
      <SignupModal show={showSignup} userProfile={userProfile} showToastMessage={showToastMessage} resetToastMessage={resetToastMessage} closeSignup={closeSignup} openLogin={openLogin} getUserProfile={getUserProfile}/>
      <LocationModal show={location.showLocation} setUserLocation={setUserLocation} showToastMessage={showToastMessage} resetToastMessage={resetToastMessage} closeLocation={closeLocation} getDeliveryRange={getDeliveryRange} openFinetuneLocation={openFinetuneLocation} deliverStatus={location.deliveryRange && location.deliveryRange.delivery_status} setStartLoader={setStartLoader} setStopLoader = {setStopLoader}/>
      <FinetuneLocation show={location.showFinetuneLocation} setUserLocation={setUserLocation} showToastMessage={showToastMessage} resetToastMessage={resetToastMessage} closeLocation={closeLocation} getDeliveryRange={getDeliveryRange} closeFinetuneLocation={closeFinetuneLocation} deliverStatus={location.deliveryRange && location.deliveryRange.delivery_status} deliveryData={deliveryData} deliveryDataError={deliveryDataError}  checkDeliveryRequest={ checkDeliveryRequest} />
      <Loader show={loaderStatus}/>
      {renderRoutes(resolvePrivateRoutes(routes))}
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.loader.toast,
    loaderStatus: state.loader.loaderStatus,
    showLogin : state.login.showLogin,
    showSignup : state.login.showSignup,
    location: state.location,
    deliveryData:state.location.deliveryData,
    deliveryDataError:state.location.deliveryDataError,
    userProfile:state.user.userProfile
  }
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openLogin,
    closeLogin,
    openSignup,
    closeSignup,
    closeLocation,
    showToastMessage,
    resetToastMessage,
    getDeliveryRange,
    openFinetuneLocation,
    closeFinetuneLocation,
    setStartLoader,
    setStopLoader,
    setUserLocation,
    checkDeliveryRequest,
    getUserProfile
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
