import { API_INVOCATION,CLEAR_CART_DATA, GET_USER_PROFILE, LOGOUT_USER,RESET_USER_PROFILE,EDIT_USER_PROFILE,GET_ORDER_LIST ,EDIT_ADDRESS, ADD_ADDRESS,GET_ALL_ADDRESS} from '../../actionType';
import * as constdata from '../../../utils/constants';
import * as consturl from '../../../utils/url';
import { getFormData } from "../../../utils/commonFunction";
/**
 * 
 *  get activity details  
 */
export const getUserProfile = (_payload) => {
 
    const url = `${consturl.BASE_URL}${consturl.PROFILE}`;
    const payload = {
        action: GET_USER_PROFILE,
        method: constdata.GET,
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url: url
    };

    return { type: API_INVOCATION, payload };
};

export const logoutUser = (_payload, resolve, reject) =>{
    return { type: LOGOUT_USER, _payload, resolve, reject };
}
/**
 * edit User Profile
 */
export const editUserProfile = (_payload,resolve, reject) => {
    const url = `${consturl.BASE_URL}${consturl.USERS}${_payload.id}`;
    const data=_payload.formData
   
    const payload = {
        action: EDIT_USER_PROFILE,
        method: "POST",
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url: url,
        resolve,
        reject,
        data
    };

    return { type: API_INVOCATION, payload };
};
/**
 * ADd Address Action
 */
export const addUserAddress = (_payload,resolve, reject) => {
    
 
    const url = `${consturl.BASE_URL}${consturl.ADD_ADDRESS}`;
    const data=_payload.data
    const payload = {
        action: ADD_ADDRESS,
        method: constdata.POST,
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url: url,
        resolve,
        reject,
        data
    };

    return { type: API_INVOCATION, payload };
};
/**
 * Edit Addresss
 */
export const editUserAddress = (_payload,resolve, reject) => {

    const data=_payload.data
    const url = `${consturl.BASE_URL}${consturl.ADD_ADDRESS}/${data.id}`;
    
    const payload = {
        action: EDIT_ADDRESS,
        method: "PUT",
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url: url,
        resolve,
        reject,
        data
    };

    return { type: API_INVOCATION, payload };
};
/**
 *  get all address action
 * */
export const getAllUserAddress = (_payload,resolve, reject) => {
    
 
    const url = `${consturl.BASE_URL}${consturl.GET_ALL_ADDRESS}`;
    // const data=_payload.data
    const payload = {
        action:GET_ALL_ADDRESS ,
        method: constdata.GET,
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url: url,
        resolve,
        reject,
      
    };

    return { type: API_INVOCATION, payload };
};

/**
 * GEt USer Order List 
 */
export const getUserOrderList = (_payload,resolve, reject) => {
    
    
 
    const url = _payload.OrderID?`${consturl.BASE_URL}/user/orders?order_no=${_payload.OrderID}`:`${consturl.BASE_URL}/user/orders?`;
    const data=getFormData(_payload)
    const payload = {
        action: GET_ORDER_LIST,
        method: constdata.GET,
        apiConfig: {
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                "client_id":constdata.CLIENT_ID,
                "client_secret":constdata.CLIENT_SECRET
            }
        },
        url: url,
        resolve,
        reject,
        data
    };

    return { type: API_INVOCATION, payload };
};

/**
 * User Profile Clear
 */

export const resetUserProfile = _payload => {
    return { type: RESET_USER_PROFILE, payload: _payload }
};

/**
 * Clear Cart data
 */




export const clearCartData = _payload => {
    debugger
    return { type: CLEAR_CART_DATA, payload: _payload }
};