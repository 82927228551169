export const API_INVOCATION = "API_INVOCATION";
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";
export const RESET_SHOW_TOAST_MESSAGE = "RESET_SHOW_TOAST_MESSAGE";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const SET_NEW_ARRIVAL_PRODUCT="SET_NEW_ARRIVAL_PRODUCT"
export const SET_NEW_ARRIVAL_PRODUCT_SUCCESS="SET_NEW_ARRIVAL_PRODUCT_SUCCESS"
export const SET_NEW_ARRIVAL_PRODUCT_ERROR="SET_NEW_ARRIVAL_PRODUCT_ERROR"

export const SET_CATEGORY_BASED_PRODUCT="SET_CATEGORY_BASED_PRODUCT"
export const SET_CATEGORY_BASED_PRODUCT_SUCCESS="SET_CATEGORY_BASED_PRODUCT_SUCCESS"
export const SET_CATEGORY_BASED_PRODUCT_ERROR="SET_CATEGORY_BASED_PRODUCT_ERROR"

export const SET_CURRENT_PRODUCT="SET_CURRENT_PRODUCT"
export const SET_CURRENT_PRODUCT_SUCCESS="SET_CURRENT_PRODUCT_SUCCESS"
export const SET_CURRENT_PRODUCT_ERROR="SET_CURRENT_PRODUCT_ERROR"

export const SET_CATEGORY = "SET_CATEGORY"
export const SET_CATEGORY_SUCCESS = "SET_CATEGORY_SUCCESS"
export const SET_CATEGORY_ERROR = "SET_CATEGORY_ERROR"

export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST"
export const SET_CATEGORY_LIST_SUCCESS = "SET_CATEGORY_LIST_SUCCESS"
export const SET_CATEGORY_LIST_ERROR = "SET_CATEGORY_LIST_ERROR"

export const ADD_FAVORITE_PRODUCT = "ADD_FAVORITE_PRODUCT"
export const ADD_FAVORITE_PRODUCT_SUCCESS = "ADD_FAVORITE_PRODUCT_SUCCESS"
export const ADD_FAVORITE_PRODUCT_ERROR = "ADD_FAVORITE_PRODUCT_ERROR"

export const GET_FAVORITE_PRODUCT =  "GET_FAVORITE_PRODUCT"
export const GET_FAVORITE_PRODUCT_SUCCESS = "GET_FAVORITE_PRODUCT_SUCCESS"
export const GET_FAVORITE_PRODUCT_ERROR = "GET_FAVORITE_PRODUCT_ERROR"

export const ADD_ADDRESS = "ADD_ADDRESS"
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS"
export const ADD_ADDRESS_ERROR = "ADD_ADDRESS_ERROR"

export const GET_ALL_ADDRESS = "GET_ALL_ADDRESS"
export const GET_ALL_ADDRESS_SUCCESS = "GET_ALL_ADDRESS_SUCCESS"
export const GET_ALL_ADDRESS_ERROR = "GET_ALL_ADDRESS_ERROR"

export const EDIT_ADDRESS = "EDIT_ADDRESS"
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS"
export const EDIT_ADDRESS_ERROR = "EDIT_ADDRESS_ERROR"

export const SET_BANNER = "SET_BANNER"
export const SET_BANNER_SUCCESS = "SET_BANNER_SUCCESS"
export const SET_BANNER_ERROR = "SET_BANNER_ERROR"

export const ADD_TO_CART = "ADD_TO_CART"
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
export const ADD_TO_CART_ERROR = "ADD_TO_CART_ERROR"

export const ADD_TO_CART_ITEM_INC = "ADD_TO_CART_ITEM_INC"
export const ADD_TO_CART_ITEM_INC_SUCCESS = "ADD_TO_CART_ITEM_INC_SUCCESS"
export const ADD_TO_CART_ITEM_INC_ERROR = "ADD_TO_CART_ITEM_INC_ERROR"

export const ADD_TO_CART_ITEM_DEC = "ADD_TO_CART_ITEM_DEC"
export const ADD_TO_CART_ITEM_DEC_SUCCESS = "ADD_TO_CART_ITEM_DEC_SUCCESS"
export const ADD_TO_CART_ITEM_DEC_ERROR = "ADD_TO_CART_ITEM_DEC_ERROR"

export const ADD_TO_CART_SAVE_ON_CHECKOUT = "ADD_TO_CART_SAVE_ON_CHECKOUT"
export const ADD_TO_CART_SAVE_ON_CHECKOUT_SUCCESS = "ADD_TO_CART_SAVE_ON_CHECKOUT_SUCCESS"
export const ADD_TO_CART_SAVE_ON_CHECKOUT_ERROR = "ADD_TO_CART_SAVE_ON_CHECKOUT_ERROR"

export const GET_USER_CART_DETAILS = "GET_USER_CART_DETAILS"
export const GET_USER_CART_DETAILS_SUCCESS = "GET_USER_CART_DETAILS_SUCCESS"
export const GET_USER_CART_DETAILS_ERROR = "GET_USER_CART_DETAILS_ERROR"

export const ADD_PRODUCT_REVIEW = "ADD_PRODUCT_REVIEW"
export const ADD_PRODUCT_REVIEW_SUCCESS = "ADD_PRODUCT_REVIEW_SUCCESS"
export const ADD_PRODUCT_REVIEW_ERROR = "ADD_PRODUCT_REVIEW_ERROR"

export const GET_PRODUCT_REVIEW = "GET_PRODUCT_REVIEW"
export const GET_PRODUCT_REVIEW_SUCCESS = "GET_PRODUCT_REVIEW_SUCCESS"
export const GET_PRODUCT_REVIEW_ERROR = "GET_PRODUCT_REVIEW_ERROR"

export const SET_MODAL = "SET_MODAL"
export const SET_MODAL_ERROR = "SET_MODAL_ERROR"

export const VALIDATE_COUPON_CODE = "VALIDATE_COUPON_CODE"
export const VALIDATE_COUPON_CODE_SUCCESS = "VALIDATE_COUPON_CODE_SUCCESS"
export const VALIDATE_COUPON_CODE_ERROR = "VALIDATE_COUPON_CODE_ERROR"

export const CONTACT_US = "CONTACT_US"
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS"
export const CONTACT_US_ERROR =   "CONTACT_US_ERROR"

export const OPEN_LOGIN = "OPEN_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";


export const  CLEAR_CART_DATA = "CLEAR_CART_DATA";

export const OPEN_SIGNUP = "OPEN_SIGNUP";
export const CLOSE_SIGNUP = "CLOSE_SIGNUP";

export const OPEN_LOCATION = "OPEN_LOCATION";
export const CLOSE_LOCATION = "CLOSE_LOCATION";

export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR"

export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE"
export const EDIT_USER_PROFILE_SUCCESS = "EDIT_USER_PROFILE_SUCCESS"
export const EDIT_USER_PROFILE_ERROR = "EDIT_USER_PROFILE_ERROR"


export const OPEN_FINETUNE_LOCATION = "OPEN_FINETUNE_LOCATION";
export const CLOSE_FINETUNE_LOCATION = "CLOSE_FINETUNE_LOCATION";



export const RESET_USER_PROFILE = "RESET_USER_PROFILE";

export const GET_RANGE = "GET_RANGE"
export const GET_RANGE_SUCCESS = "GET_RANGE_SUCCESS"
export const GET_RANGE_ERROR = "GET_RANGE_ERROR"

export const SET_USER_LOCATION = "SET_USER_LOCATION";

export const GET_FAQ = "GET_FAQ"
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS"
export const GET_FAQ_ERROR = "GET_FAQ_ERROR"

export const GET_ORDER_LIST = "GET_ORDER_LIST"
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS"
export const GET_ORDER_LIST_ERROR = "GET_ORDER_LIST_ERROR"

export const GET_APP_LINK = "GET_APP_LINK"
export const GET_APP_LINK_ERROR = "GET_APP_LINK_ERROR"
export const GET_APP_LINK_SUCCESS = "GET_APP_LINK_SUCCESS"

export const CHECK_PAYMENT_STATUS = "CHECK_PAYMENT_STATUS";
export const CHECK_PAYMENT_STATUS_SUCCESS = "CHECK_PAYMENT_STATUS_SUCCESS";
export const CHECK_PAYMENT_STATUS_ERROR = "CHECK_PAYMENT_STATUS_ERROR";

export const CART_CHECKOUT_SAVE = "CART_CHECKOUT_SAVE";
export const CART_CHECKOUT_SAVE_SUCCESS = "CART_CHECKOUT_SAVE_SUCCESS";
export const CART_CHECKOUT_SAVE_ERROR = "CART_CHECKOUT_SAVE_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";

export const CHECK_DELIVERY = "CHECK_DELIVERY";
export const CHECK_DELIVERY_SUCCESS = "CHECK_DELIVERY_SUCCESS";
export const CHECK_DELIVERY_ERROR = "CHECK_DELIVERY_ERROR";

export const DELIVERY_ADDRESS_SELECT = "DELIVERY_ADDRESS_SELECT";
export const BILLING_ADDRESS_SELECT = "BILLING_ADDRESS_SELECT";